import request from '@/utils/request';

// 考试列表
export const examlist = params => request.post('study/exam/list', params)

// 考试目录
export const examfolder = params => request.get('study/exam/folder/tree', params)

// 开始考试
export const examStart = (params, course) => request.post(`study/${course}exam/start`, params)

// 考试切屏
export const examSwitch = (params, course) => request.post(`study/${course}exam/switch`, params)

// 考试自动保存
export const examAutoSave = (params, course) => request.post(`study/${course}exam/autosave`, params)

// 考试交卷
export const examSubmit = (params, course) => request.post(`study/${course}exam/submit`, params)

// 查看答卷
export const examResult = (params, course) => request.post(`study/${course}exam/result`, params)

// 学员试卷
export const submitPaperDetail = params => request.post(`lms/project/track/${params.taskId}/exam/${params.detailId}/member/${params.userId}/paper/${params.submitId}/detail`, params)

// 修改考试成绩
export const examModify = params => request.post(`lms/exam/modify`, params)

// 批阅试卷列表
export const getReviewList = params => request.post('study/exam/making/list', params);

// 批阅试题
export const getReviewDetail = params => request.post('study/exam/making/questions', params);

// 提交阅卷
export const submitReview = params => request.post('study/exam/making/submit', params);

// 考试签名
export const signExam = (params, course) => request.post(`study/${course}exam/sign`, params);

// 补考详情
export const getRetakeDetail = (params) => request.get(`study/task/${params.id}/${params.did}/${params.reexamId}/reexam`)
// 补考列表
export const getRetakeList = (params) => request.post(`study/reexam/list`, params)
// 保存错题反馈
export const saveQuestionReport = (params) => request.post(`study/exam/savequestionreport`, params)


