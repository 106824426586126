<template>
  <a-spin :spinning="loading" style="heihgt: 100%">
    <div class="wrap">
      <div class="questions">
        <a-card :bordered="false" style="min-height: calc(100vh - 430px)">
          <div
            class="questions-item"
            v-for="(item, index) in dataInfo.questions"
            :key="item.questionId"
          >
            <div
              class="text"
              :style="{
                width: item.questionType == 8 ? '100%' : 'calc(100% - 116px)',
              }"
            >
              <div class="questions-type" v-if="item.questionType == 4">
                <div class="number">
                  {{ $t("exam.quesition_num", [toChinesNum(index + 1)]) }}
                  （{{ item.examScore }} {{ $t("CM_Points") }}）
                  <!-- 第 题 分 -->
                </div>
                <p class="title">
                  [{{ $t("LB_Exam_BlankFilling") }}]{{ item.title }}
                </p>
                <!-- 填空题 -->
                <template v-if="item.titimgs && item.titimgs.length">
                  <exam-media :mediaList="item.titimgs" />
                </template>
                <div class="user-answer">
                  <div
                    v-for="(answer, answerIndex) in item.answer2"
                    :key="answerIndex"
                  >
                    <div class="user-answer-item">
                      <span
                        >{{ $t("exam.fill_in_blanks")
                        }}{{ answerIndex + 1 }}：</span
                      >
                      <!-- 填空 -->
                      {{ answer || $t("exam.not_answer") }}
                      <!-- 未答题 -->
                    </div>
                  </div>
                </div>
                <div class="correct-answer">
                  <h6>{{ $t("exam.answers") }}：</h6>
                  <!-- 答题答案： -->
                  <div
                    v-for="(answers, answersIndex) in item.answer1Arr"
                    :key="answersIndex"
                  >
                    <div class="item">
                      {{ $t("exam.fill_in_blanks") }}{{ answersIndex + 1 }}：
                      <!-- 填空 -->
                      <a-tag
                        v-for="(answer, answerIndex) in answers"
                        :key="answerIndex"
                      >
                        {{ answer }}
                      </a-tag>
                    </div>
                  </div>
                </div>
                <div class="analysis">
                  <h6>{{ $t("LB_Exam_QuestionAnalysis") }}：</h6>
                  <!-- 答题解析： -->
                  <p>{{ item.analysis || "-" }}</p>
                  <analysis-media
                    v-if="item.analysisimgs"
                    :data="item.analysisimgs"
                  />
                </div>
              </div>
              <div class="questions-type" v-else-if="item.questionType == 5">
                <div class="number">
                  {{ $t("exam.quesition_num", [toChinesNum(index + 1)]) }}
                  （{{ item.examScore }} {{ $t("CM_Points") }}）
                  <!-- 第 题 分 -->
                </div>
                <p class="title">[{{ $t("CM_QA") }}]{{ item.title }}</p>
                <!-- 问答题 -->
                <template v-if="item.titimgs && item.titimgs.length">
                  <exam-media :mediaList="item.titimgs" />
                </template>
                <div class="user-answer">
                  <div
                    v-for="(answer, answerIndex) in item.answer2"
                    :key="answerIndex"
                  >
                    <div class="user-answer-item">
                      {{ answer || $t("exam.not_answer") }}
                      <!-- 未答题 -->
                    </div>
                  </div>
                </div>
                <div
                  class="opt-pics"
                  v-viewer="{ modal: true, title: false }"
                  v-if="item.answerImg"
                >
                  <div
                    class="opt-pics-item ed"
                    v-for="(itemPic, itemPicIndex) in item.answerImg"
                    :key="itemPicIndex"
                  >
                    <img :src="itemPic" alt="picture" />
                  </div>
                </div>
                <div class="analysis" v-if="item.answer1Arr.length">
                  <h6>{{ $t("exam.score_keywords") }}</h6>
                  <!-- 判分关键词 -->
                  <p
                    v-for="(keyword, keywordIndex) in item.answer1Arr"
                    :key="keywordIndex"
                  >
                    <!-- 关键词 -->
                    {{ $t("exam.keyword") }}{{ keywordIndex + 1 }}：{{
                      keyword.option.split("$").join("、")
                    }}
                    <span
                      >{{ $t("exam.score_ratio") }}：{{ keyword.value }}%</span
                    >
                    <!-- 得分比例% -->
                  </p>
                </div>
                <div class="analysis">
                  <h6>{{ $t("LB_Exam_QuestionAnalysis") }}：</h6>
                  <!-- 答题解析： -->
                  <p>{{ item.analysis || "-" }}</p>
                  <analysis-media
                    v-if="item.analysisimgs"
                    :data="item.analysisimgs"
                  />
                </div>
              </div>
              <div class="questions-type" v-else-if="item.questionType == 8">
                <div class="number">
                  {{ $t("exam.quesition_num", [toChinesNum(index + 1)]) }}
                  （{{ item.examScore }} {{ $t("CM_Points") }}）
                  <!-- 第 题 分 -->
                </div>
                <p class="title">[{{ $t("LB_Exam_Casus") }}]{{ item.title }}</p>
                <!-- 案例题 -->
                <template v-if="item.titimgs && item.titimgs.length">
                  <exam-media :mediaList="item.titimgs" />
                </template>
                <div
                  class="questions-item case"
                  v-for="(caseItem, caseIndex) in item.list"
                  :key="caseIndex"
                >
                  <div class="text">
                    <div
                      class="questions-type"
                      v-if="caseItem.questionType == 4"
                    >
                      <div class="number">
                        {{ $t("exam.quesition_num", [caseIndex + 1]) }}
                        （{{ caseItem.examScore }} {{ $t("CM_Points") }}）
                        <!-- 第 题 分 -->
                      </div>
                      <p class="title">
                        [{{ $t("LB_Exam_BlankFilling") }}]{{ caseItem.title }}
                      </p>
                      <!-- 填空题 -->
                      <template
                        v-if="caseItem.titimgs && caseItem.titimgs.length"
                      >
                        <exam-media :mediaList="caseItem.titimgs" />
                      </template>
                      <div class="user-answer">
                        <div
                          v-for="(answer, answerIndex) in caseItem.answer2"
                          :key="answerIndex"
                        >
                          <div class="user-answer-item">
                            <span
                              >{{ $t("exam.fill_in_blanks")
                              }}{{ answerIndex + 1 }}：</span
                            >
                            <!-- 填空 -->
                            {{ answer || $t("exam.not_answer") }}
                            <!-- 未答题 -->
                          </div>
                        </div>
                      </div>
                      <div class="correct-answer">
                        <h6>{{ $t("exam.answers") }}：</h6>
                        <!-- 答题答案： -->
                        <div
                          v-for="(answers, answersIndex) in caseItem.answer1Arr"
                          :key="answersIndex"
                        >
                          <div class="item">
                            {{ $t("exam.fill_in_blanks")
                            }}{{ answersIndex + 1 }}：
                            <!-- 填空 -->
                            <a-tag
                              v-for="(answer, answerIndex) in answers"
                              :key="answerIndex"
                            >
                              {{ answer }}
                            </a-tag>
                          </div>
                        </div>
                      </div>
                      <div class="analysis">
                        <h6>{{ $t("LB_Exam_QuestionAnalysis") }}：</h6>
                        <!-- 答题解析： -->
                        <p>{{ caseItem.analysis || "-" }}</p>
                        <analysis-media
                          v-if="caseItem.analysisimgs"
                          :data="caseItem.analysisimgs"
                        />
                      </div>
                    </div>
                    <div
                      class="questions-type"
                      v-else-if="caseItem.questionType == 5"
                    >
                      <div class="number">
                        {{ $t("exam.quesition_num", [caseIndex + 1]) }}
                        （{{ caseItem.examScore }} {{ $t("CM_Points") }}）
                        <!-- 第 题 分 -->
                      </div>
                      <p class="title">
                        [{{ $t("CM_QA") }}]{{ caseItem.title }}
                      </p>
                      <!-- 问答题 -->
                      <template
                        v-if="caseItem.titimgs && caseItem.titimgs.length"
                      >
                        <exam-media :mediaList="caseItem.titimgs" />
                      </template>
                      <div class="user-answer">
                        <div
                          v-for="(answer, answerIndex) in caseItem.answer2"
                          :key="answerIndex"
                        >
                          <div class="user-answer-item">
                            {{ answer || $t("exam.not_answer") }}
                            <!-- 未答题 -->
                          </div>
                        </div>
                      </div>
                      <div
                        class="opt-pics"
                        v-viewer="{ modal: true, title: false }"
                        v-if="caseItem.answerImg"
                      >
                        <div
                          class="opt-pics-item ed"
                          v-for="(itemPic, itemPicIndex) in caseItem.answerImg"
                          :key="itemPicIndex"
                        >
                          <img :src="itemPic" alt="picture" />
                        </div>
                      </div>
                      <div class="analysis" v-if="caseItem.answer1Arr.length">
                        <h6>{{ $t("exam.score_keywords") }}</h6>
                        <!-- 判分关键词 -->
                        <p
                          v-for="(keyword, keywordIndex) in caseItem.answer1Arr"
                          :key="keywordIndex"
                        >
                          <!-- 关键词 -->
                          {{ $t("exam.keyword") }}{{ keywordIndex + 1 }}：{{
                            keyword.option.split("$").join("、")
                          }}
                          <span
                            >{{ $t("exam.score_ratio") }}：{{
                              keyword.value
                            }}%</span
                          >
                          <!-- 得分比例： -->
                        </p>
                      </div>
                      <div class="analysis">
                        <h6>{{ $t("LB_Exam_QuestionAnalysis") }}：</h6>
                        <!-- 答题解析： -->
                        <p>{{ caseItem.analysis || "-" }}</p>
                        <analysis-media
                          v-if="caseItem.analysisimgs"
                          :data="caseItem.analysisimgs"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="score">
                    <div class="score-box">
                      <span>{{ $t("CM_GetScore") }} </span>
                      <!-- 得分 -->
                      <a-input-number
                        v-model:value="caseItem.score"
                        :min="0"
                        :max="caseItem.examScore"
                        :step="0.5"
                        :precision="1"
                        @change="scoreChange"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="score" v-if="item.questionType != 8">
              <div class="score-box">
                <span>{{ $t("CM_GetScore") }} </span>
                <!-- 得分 -->
                <a-input-number
                  v-model:value="item.score"
                  :min="0"
                  :max="item.examScore"
                  :step="0.5"
                  :precision="1"
                  @change="scoreChange"
                />
              </div>
            </div>
          </div>
        </a-card>
      </div>
      <div class="exam-info">
        <a-affix :offset-top="60">
          <a-card :bordered="false">
            <div class="exam">
              <h3>{{ $t("exam.exam_info") }}</h3>
              <!-- 考试信息 -->
              <div class="explain-box">
                <div class="explain-row">
                  <div class="l">{{ $t("exam.exam_name") }}：</div>
                  <!-- 考试名称： -->
                  <div class="r">
                    {{
                      dataInfo.reexamId
                        ? dataInfo.reexamName
                        : dataInfo.detailName
                    }}
                  </div>
                </div>
                <div class="explain-row">
                  <div class="l">{{ $t("Pub_Lab_ExamSource") }}：</div>
                  <!-- 考试来源： -->
                  <div class="r">{{ dataInfo.taskName }}</div>
                </div>
                <div class="explain-row">
                  <div class="l">{{ $t("exam.approval_count") }}：</div>
                  <!-- 批阅数： -->
                  <div class="r">
                    {{ dataInfo.totals - dataInfo.waits }}/{{ dataInfo.totals }}
                  </div>
                </div>
                <div class="explain-row">
                  <div class="l">{{ $t("exam.approval_progress") }}：</div>
                  <!-- 批阅进度： -->
                  <div class="r progress">
                    <div class="num">
                      {{
                        dataInfo.totals == 0 && dataInfo.waits == 0
                          ? "0%"
                          : toPercent(
                              (dataInfo.totals - dataInfo.waits) /
                                dataInfo.totals
                            )
                      }}
                    </div>
                    <div>
                      <a-progress
                        type="circle"
                        :showInfo="false"
                        :percent="
                          ((dataInfo.totals - dataInfo.waits) /
                            dataInfo.totals) *
                          100
                        "
                        :width="18"
                        :strokeWidth="20"
                        strokeColor="#1ab700"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="paper">
              <h3>{{ $t("exam.answer_info") }}</h3>
              <!-- 答卷信息 -->
              <div class="explain-box">
                <div class="explain-row">
                  <div class="l">{{ $t("exam.student_name") }}：</div>
                  <!-- 学员姓名： -->
                  <div class="r">***</div>
                </div>
                <div class="explain-row">
                  <div class="l">{{ $t("exam.exam_time") }}：</div>
                  <!-- 开考时间： -->
                  <div class="r">{{ dateFormat(dataInfo.startTime) }}</div>
                </div>
                <div class="explain-row">
                  <div class="l">{{ $t("CM_EndTime") }}：</div>
                  <!-- 结束时间： -->
                  <div class="r">{{ dateFormat(dataInfo.endTime) }}</div>
                </div>
                <div class="explain-row">
                  <div class="l">{{ $t("exam.exam_score") }}：</div>
                  <!-- 考试得分： -->
                  <div class="r">{{ totalScore }}</div>
                </div>
              </div>
            </div>
            <a-button
              type="primary"
              block
              @click="handleSubmit"
              :loading="saveLoading"
              >{{ $t("CM_Submit") }}</a-button
            >
            <!-- 提交 -->
          </a-card>
        </a-affix>
      </div>
    </div>
  </a-spin>
</template>
<script>
import { useI18n } from "vue-i18n";
import { onMounted, reactive, toRefs, getCurrentInstance } from "vue";
import { useRoute, useRouter } from "vue-router";
import { getReviewDetail, submitReview } from "@/api/exam";
import { dateFormat, toPercent, toChinesNum } from "@/utils/tools";
import calculate from "@/utils/calculate";
import examMedia from "../exam-media.vue";
import analysisMedia from "../analysis-media.vue";

export default {
  components: {
    examMedia,
    analysisMedia,
  },
  setup() {
    const { t: $t } = useI18n();
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      taskId: Number(route.query.taskId),
      detailId: Number(route.query.detailId),
      reexamId: Number(route.query.reexamId),
      dataInfo: {},
      loading: false,
      saveLoading: false,
      totalScore: 0,
    });

    const scoreChange = (e) => {
      let totalScore = 0;
      state.dataInfo.questions.forEach((item) => {
        if (item.questionType != 8) {
          totalScore = calculate.add(totalScore, item.score);
        } else {
          item.list.forEach((item2) => {
            totalScore = calculate.add(totalScore, item2.score);
          });
        }
      });
      state.totalScore = totalScore;
    };

    const handleSubmit = () => {
      let allData = JSON.parse(JSON.stringify(state.dataInfo));
      allData.questions = allData.questions.filter(
        (item) => item.questionType != 8
      );
      let caseData = [];
      state.dataInfo.questions.forEach((item) => {
        if (item.questionType == 8) {
          caseData.push(...item.list);
        }
      });
      allData.questions.push(...caseData);
      state.saveLoading = true;
      submitReview(allData).then((res) => {
        state.saveLoading = false;
        proxy.$message.success($t("exam.successful_approval"));
        // 阅卷成功
        getDetail();
      });
    };

    const caseRender = (list) => {
      list.forEach((item) => {
        item.examScore = item.score;
        item.score = 0;
        if (item.questionType == 4) {
          item.title = item.title.replace(/＿/g, "____________");
          item.answer1Arr = item.answer1.map((str) => {
            return str.split("$");
          });
        } else if (item.questionType == 5) {
          item.answer1Arr = item.answer1Arr || [];
          if (item.answer1)
            item.answer1Arr = item.answer1.map((d) => JSON.parse(d));
        }
      });
    };

    const getDetail = () => {
      state.dataInfo = {};
      state.loading = true;
      getReviewDetail({
        taskId: state.taskId,
        detailId: state.detailId,
        reexamId: state.reexamId,
      })
        .then((res) => {
          if (res.ret == 0) {
            let d = res.data;
            if (d.questions) {
              d.questions.forEach((item) => {
                item.examScore = item.score;
                item.score = 0;
                if (item.questionType == 4) {
                  item.title = item.title.replace(/＿/g, "____________");
                  item.answer1Arr = item.answer1.map((str) => {
                    return str.split("$");
                  });
                } else if (item.questionType == 5) {
                  item.answer1Arr = item.answer1Arr || [];
                  if (item.answer1)
                    item.answer1Arr = item.answer1.map((d) => JSON.parse(d));
                } else if (item.questionType == 8) {
                  caseRender(item.list);
                }
              });
              d.questions.sort((a, b) => a.questionType - b.questionType);
              state.dataInfo = d;
            } else {
              router.push({
                path: "/exam/review/end",
              });
            }
          } else {
            // proxy.$message.error("请求失败，" + res.msg);
          }
        })
        .finally(() => {
          state.loading = false;
        });
    };

    const getResourceType = (url) => {
      if (!url || url == "") {
        return "";
      }
      const imgFormat = ["jpg", "png", "gif"];
      const videoFormat = ["mp4"];
      const audioFormat = ["mp3"];
      let u = url.split(".");
      let suffix = u[u.length - 1].toLowerCase();
      let type = "";
      if (imgFormat.indexOf(suffix) >= 0) {
        type = "image";
      } else if (videoFormat.indexOf(suffix) >= 0) {
        type = "video";
      } else if (audioFormat.indexOf(suffix) >= 0) {
        type = "audio";
      }
      return type;
    };

    onMounted(() => {
      getDetail();
    });

    return {
      toChinesNum,
      dateFormat,
      toPercent,
      ...toRefs(state),
      scoreChange,
      handleSubmit,
      getResourceType,
    };
  },
};
</script>
<style lang="less" scoped>
.wrap {
  .mixinWrap();
  padding: 20px 0;
  .mixinFlex(space-between);
  .ant-card-body {
    padding: 16px;
  }
  .questions {
    width: calc(100% - 350px);
    &-item {
      .mixinFlex(space-between);
      border-bottom: 1px solid #f0f0f0;
      padding-top: 14px;
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        border-bottom: none;
      }
      &.case {
        padding: 10px;
        background-color: #fafafa;
        margin: 10px 0;
        border-bottom: 0;
      }
      .text {
        width: calc(100% - 116px);
        .number {
          color: #8c8c8c;
        }
        .questions-type {
          .title {
            font-size: 16px;
            line-height: 1.5;
            margin: 10px 0 4px;
          }
          h6 {
            font-size: 14px;
            line-height: 1.5;
            margin: 10px 0 6px;
          }
          .user-answer {
            &-item {
              display: inline-block;
              border: 1px solid #dadada;
              border-radius: 4px;
              padding: 8px;
              margin-top: 4px;
              width: 100%;
              min-height: 40px;
              span {
                color: #8c8c8c;
              }
            }
          }
          .opt-pics {
            margin-top: 12px;
            &-item {
              display: inline-block;
              width: 105px;
              height: 105px;
              margin-right: 10px;
              border-radius: 4px;
              position: relative;
              &.ed {
                width: 105px;
                height: 105px;
                overflow: hidden;
                justify-content: center;
                align-items: center;
                font-size: 0;
                position: relative;
                user-select: none;
                display: inline-flex;
                img {
                  width: 100%;
                  height: 100%;
                  cursor: pointer;
                }
              }
            }
          }
          .correct-answer {
            .item {
              color: #8c8c8c;
              margin-top: 4px;
            }
          }
          .analysis {
            p {
              color: #8c8c8c;
              white-space: pre-line;
              word-break: break-all;
              margin-bottom: 8px;

              span {
                padding-left: 20px;
                color: #2e3d58;
              }
            }
          }
        }
      }
      .score {
        width: 106px;
        &-box {
          .mixinFlex(space-between; center);
          ::v-deep(.ant-input-number) {
            width: 72px !important;
          }
        }
      }
    }
  }
  .exam-info {
    width: 330px;
    .paper {
      border-top: 1px solid #f0f0f0;
      margin-top: 20px;
      padding-top: 20px;
      margin-bottom: 10px;
    }
    .progress {
      min-width: 200px;
      .mixinFlex(flex-end; center);
      color: #1ab700 !important;
      font-weight: bold;
      .num {
        margin-right: 10px;
      }
    }
    .explain-row {
      .mixinFlex(space-between);
      .l {
        min-width: 80px;
      }
      .r {
        text-align: right;
      }
    }
  }
}
</style>
