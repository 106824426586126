<template>
  <div
    class="media"
    :class="className[type]"
    v-for="(t, tIndex) in mediaList"
    :key="tIndex"
  >
    <a-image v-if="getResourceType(t) == 'image'" :src="t" />
    <video
      controlslist="nodownload"
      v-else-if="getResourceType(t) == 'video'"
      :src="t"
      controls
    />
    <audio
      controlslist="nodownload"
      v-else-if="getResourceType(t) == 'audio'"
      :src="t"
      controls
    />
  </div>
</template>
<script setup>
defineProps({
  mediaList: Array,
  type: {
    type: String,
    default: '',
  },
})
const className = {
  option: 'opt-media-wrap',
}

function getResourceType(url) {
  if (!url || url == "") {
    return "";
  }
  const imgFormat = ["jpg", "png", "gif"];
  const videoFormat = ["mp4"];
  const audioFormat = ["mp3"];
  let u = url.split(".");
  let suffix = u[u.length - 1].toLowerCase();
  let type = "";
  if (imgFormat.indexOf(suffix) >= 0) {
    type = "image";
  } else if (videoFormat.indexOf(suffix) >= 0) {
    type = "video";
  } else if (audioFormat.indexOf(suffix) >= 0) {
    type = "audio";
  }
  return type;
};
</script>
<style lang="less" scoped>
.media {
  width: 400px;
  margin: 7px 0;
  overflow: hidden;
  font-size: 0;
  audio,
  video {
    width: 100%;
    font-size: 0;
  }
  audio::-webkit-media-controls-enclosure {
    border-radius: 0;
  }
  ::v-deep(.ant-image) {
    width: 100%;
    height: 150px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
    img {
      width: auto;
      max-width: 100%;
      cursor: pointer;
    }
  }
}
.opt-media-wrap {
  width: 400px;
  ::v-deep(.ant-image) {
    height: 150px;
  }
}
</style>