<template>
  <div class="media">
    <div class="media-item" :class="'media-item-' + item.type" v-for="(item, i) in mediaList" :key="i">
      <a-image
        v-if="item.type === 'image'"
        :src="item.url"
      />
      <video
        v-else-if="item.type === 'video'"
        :src="item.url"
        controls
      />
      <audio
        v-else-if="item.type === 'audio'"
        :src="item.url"
        controls
      />
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, watch } from 'vue';
export default defineComponent({
  props: {
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  setup(props) {
    const mediaList = ref([]);
    const imgFormat = ['png', 'jpg', 'jpeg', 'webp', 'gif'];
    const videoFormat = ['mp4'];
    const audioFormat = ['mp3'];
    const getResourceType = url => {
      if (!url || url == '') {
        return '';
      }
      let u = url.split('.');
      let suffix = u[u.length - 1].toLowerCase();
      let type = '';
      if (imgFormat.indexOf(suffix) >= 0) {
        type = 'image';
      } else if (videoFormat.indexOf(suffix) >= 0) {
        type = 'video';
      } else if (audioFormat.indexOf(suffix) >= 0) {
        type = 'audio';
      }
      return type;
    };
    watch(
      () => props.data,
      list => {
        mediaList.value = list.map((item) => {
          return {
            url: item,
            type: getResourceType(item),
          };
        });
        console.log(mediaList.value);
      },
      { immediate: true, deep: true },
    );
    return {
      mediaList,
    };
  },
});
</script>
<style lang="less" scoped>
.media {
  display: flex;
  flex-wrap: wrap;
}
.media-item {
  position: relative;
  width: 500px;
  min-height: 30px;
  margin: 5px 5px 5px 0;
  audio,
  video {
    width: 100%;
    font-size: 0;
  }
  audio::-webkit-media-controls-enclosure {
    border-radius: 0;
  }
  .del {
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
    border-radius: 0 0 0 10px;
    text-align: center;
    line-height: 18px;
    background-color: rgba(0, 0, 0, 0.6);
    cursor: pointer;
    transition: all 0.2s;
    font-size: 12px;
    color: #fff;
  }
}
.media-item-image {
  width: 80px;
  height: 80px;
  border: 1px solid #ccc;
  overflow: hidden;
  ::v-deep(.ant-image) {
    width: 80px;
    height: 80px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }
}
</style>